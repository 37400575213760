import { createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#4682B4', // Steel Blue
    },
  },
});

export default theme;